import { createAction } from '@reduxjs/toolkit'
import { cloneDeep } from 'lodash'
import { AppState } from '../../appState'

export const setFormTemplatesDraft = createAction<any>(
  'draft/setFormTemplatesDraft',
)

export const setFormTemplatesDraftReducer = (
  state: AppState['draft'],
  action: ReturnType<typeof setFormTemplatesDraft>,
): void => {
  state.formTemplates = cloneDeep(action.payload)
}
