import React, { FC } from 'react'
import styled, { css } from 'styled-components'
import { IIcon, Icon, Text } from '@ads/front-ds'

interface ILargeTabButton {
  label: string
  caption?: string
  icon: IIcon['name']
  selected?: boolean
  disabled?: boolean
  onClick: () => void
}

export const LargeTabButton: FC<ILargeTabButton> = ({
  label,
  caption,
  icon,
  selected,
  disabled,
  onClick,
}) => (
  <Wrapper
    selected={selected}
    disabled={disabled}
    onClick={disabled ? undefined : onClick}
  >
    <Icon name={icon} />
    <Text as="div" fontSize="l" color="grey100">
      {label}
    </Text>
    {!!caption && (
      <Text as="div" fontSize="l" color="grey50">
        {caption}
      </Text>
    )}
  </Wrapper>
)

const Wrapper = styled.div<{ selected?: boolean; disabled?: boolean }>`
  ${p => css`
    display: flex;
    flex-direction: column;
    gap: ${p.theme.space.s}px;
    background: ${p.selected
      ? p.theme.colors.primary05
      : p.theme.colors.inverted100};
    border: ${p.selected
      ? p.theme.borders.thinPrimary20
      : p.theme.borders.thinGrey20};
    border-radius: ${p.theme.radii.rounded};
    box-sizing: border-box;
    padding: ${p.theme.space.m}px;

    ${p.disabled
      ? css`
          filter: grayscale(1);
          cursor: not-allowed;
          > * {
            opacity: 0.5;
          }
        `
      : css`
          cursor: pointer;
        `}
  `}
`
