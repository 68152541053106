/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Error } from '../models/Error'
import type { RequestCreateFormContent } from '../models/RequestCreateFormContent'
import type { RequestSetPushConsentForm } from '../models/RequestSetPushConsentForm'
import type { ResponseFormContent } from '../models/ResponseFormContent'
import type { ResponseGetPushConsentFormWithTemplates } from '../models/ResponseGetPushConsentFormWithTemplates'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class FormService {
  /**
   * Ustawia typ i parametry formularza zgód dla push
   * @returns any ustawienia zapisane
   * @throws ApiError
   */
  public static setPushConsentForm({
    requestBody,
  }: {
    /**
     * Ustawienia formularza zgód dla push
     */
    requestBody: RequestSetPushConsentForm
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/ma-form/push-consent-form',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Pobiera ustawienia formularza zgód dla push oraz szablony formularzy
   * @returns ResponseGetPushConsentFormWithTemplates Ustawienia formularza zgód dla push oraz szablony formularzy
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static getPushConsentFormWithTemplates({
    shopId,
    formGroupId,
  }: {
    shopId: number
    formGroupId: number
  }): CancelablePromise<ResponseGetPushConsentFormWithTemplates | Error> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/ma-form/push-consent-form-with-templates',
      query: {
        shopId: shopId,
        formGroupId: formGroupId,
      },
    })
  }

  /**
   * Tworzy zawartośc formularza
   * @returns any ustawienia zapisane
   * @throws ApiError
   */
  public static createFormContent({
    requestBody,
  }: {
    /**
     * zawartość formularza
     */
    requestBody: RequestCreateFormContent
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/ma-form/form-content',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Zwraca podgląd formularza
   * @returns any podgląd formularza jako html
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static getFormPreview({
    requestBody,
  }: {
    /**
     * zawartość formularza
     */
    requestBody: RequestCreateFormContent
  }): CancelablePromise<
    | {
        html?: any
      }
    | Error
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/ma-form/form-content/preview',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Pobiera zawartość wypełnionego formularza
   * @returns ResponseFormContent dane opisujące wypełniony formularz
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static getFormContent({
    shopId,
    formId,
  }: {
    shopId: number
    formId: number
  }): CancelablePromise<ResponseFormContent | Error> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/ma-form/form-content/{shopId}/{formId}',
      path: {
        shopId: shopId,
        formId: formId,
      },
    })
  }
}
