import { createAction } from '@reduxjs/toolkit'
import { cloneDeep } from 'lodash'
import { AppState } from '../../appState'

export const addTemplatesAfterSave = createAction<any>(
  'form/addTemplatesAfterSave',
)

export const addTemplatesAfterSaveReducer = (
  state: AppState['form'],
  action: ReturnType<typeof addTemplatesAfterSave>,
): void => {
  state.addTemplatesAfterSave = cloneDeep(action.payload)
}
