import { GenericCampaignCreationPropertiesType } from '@ads/front-core/api'
import { Extensions } from '../interfaces'

export interface IGenericCreationFileParameter {
  extensions: Extensions[]
  maxFileSize: string
  maxFileSizeInBytes: number
}

export interface IGenericCreationParameters {
  dimension: string
  fileParameters: IGenericCreationFileParameter[]
}

const DEFAULT_IMAGE_EXTENSIONS = [
  Extensions.PNG,
  Extensions.JPG,
  Extensions.JPEG,
]

const createImageFileParameters = (
  maxFileSizeInKb: number,
  extensions: Extensions[] = DEFAULT_IMAGE_EXTENSIONS,
): IGenericCreationFileParameter => {
  return {
    extensions,
    maxFileSize: `${maxFileSizeInKb}kB`,
    maxFileSizeInBytes: 1024 * maxFileSizeInKb,
  }
}

const zipFileParameters: IGenericCreationFileParameter = {
  extensions: [Extensions.ZIP],
  maxFileSize: '150kB',
  maxFileSizeInBytes: 1024 * 150,
}

export const genericCreationParameters: Record<
  GenericCampaignCreationPropertiesType,
  IGenericCreationParameters
> = {
  VIDEO: {
    dimension: undefined,
    fileParameters: [
      {
        extensions: [Extensions.MP4],
        maxFileSize: '5MB',
        maxFileSizeInBytes: 1024 * 1024 * 5,
      },
    ],
  },
  BAR: {
    dimension: '300x50',
    fileParameters: [createImageFileParameters(30)],
  },
  BAR_LP_WP: {
    dimension: '300x60',
    fileParameters: [createImageFileParameters(30)],
  },
  STICKY: {
    dimension: '320x50',
    fileParameters: [createImageFileParameters(30)],
  },
  CONTENT_BOX_MEDIUM: {
    dimension: '940x300',
    fileParameters: [createImageFileParameters(60)],
  },
  CONTENT_BOX_LARGE: {
    dimension: '1260x300',
    fileParameters: [createImageFileParameters(150)],
  },
  BANNER_300x250: {
    dimension: '300x250',
    fileParameters: [
      createImageFileParameters(60, [
        ...DEFAULT_IMAGE_EXTENSIONS,
        Extensions.GIF,
      ]),
      zipFileParameters,
    ],
  },
  BANNER_300x600: {
    dimension: '300x600',
    fileParameters: [
      createImageFileParameters(60, [
        ...DEFAULT_IMAGE_EXTENSIONS,
        Extensions.GIF,
      ]),
      zipFileParameters,
    ],
  },
  BANNER_750x200: {
    dimension: '750x200',
    fileParameters: [
      createImageFileParameters(60, [
        ...DEFAULT_IMAGE_EXTENSIONS,
        Extensions.GIF,
      ]),
      zipFileParameters,
    ],
  },
  BANNER_350x216: {
    dimension: '350x216',
    fileParameters: [createImageFileParameters(50)],
  },
  CUBE_3D: {
    dimension: undefined,
    fileParameters: [zipFileParameters],
  },
  MOTION_AD: {
    dimension: undefined,
    fileParameters: [zipFileParameters],
  },
  SCRATCH: {
    dimension: undefined,
    fileParameters: [zipFileParameters],
  },
  SLASH: {
    dimension: undefined,
    fileParameters: [zipFileParameters],
  },
  RECTANGLE_300x250: {
    dimension: '300x250',
    fileParameters: [createImageFileParameters(40), zipFileParameters],
  },
  MEGA_DOUBLE_BILLBOARD_970x300: {
    dimension: '970x300',
    fileParameters: [createImageFileParameters(70), zipFileParameters],
  },
  DOUBLE_BILLBOARD_750x200: {
    dimension: '750x200',
    fileParameters: [createImageFileParameters(50), zipFileParameters],
  },
  HALFPAGE_300x600: {
    dimension: '300x600',
    fileParameters: [createImageFileParameters(60), zipFileParameters],
  },
  TRIPLE_BILLBOARD_750x300: {
    dimension: '750x300',
    fileParameters: [createImageFileParameters(60), zipFileParameters],
  },
  BANNER_300x100: {
    dimension: '300x100',
    fileParameters: [createImageFileParameters(20), zipFileParameters],
  },
  WIDEBOARD_970x200: {
    dimension: '970x200',
    fileParameters: [createImageFileParameters(60), zipFileParameters],
  },
  SKYSCRAPER_160x600: {
    dimension: '160x600',
    fileParameters: [createImageFileParameters(40), zipFileParameters],
  },
  BILLBOARD_750x100: {
    dimension: '750x100',
    fileParameters: [createImageFileParameters(40), zipFileParameters],
  },
  SCALABLE_XL_BANNER_600x400: {
    dimension: '600x400',
    fileParameters: [createImageFileParameters(40), zipFileParameters],
  },
  ANY_FILE_RESOURCE: {
    dimension: null,
    fileParameters: [],
  },
  ANY_ARTICLE_RESOURCE: {
    dimension: null,
    fileParameters: [],
  },
  ARTICLE_CONTENT_RESOURCE: undefined,
}

export const getGenericCreationParameters = (
  type: GenericCampaignCreationPropertiesType,
): IGenericCreationParameters => genericCreationParameters[type]
