import { createReducer } from '@reduxjs/toolkit'
import { preloadedState } from '../../appState'
import {
  setFormTemplates,
  setFormTemplatesReducer,
} from './setFormTemplatesReducer'
import {
  addTemplatesAfterSave,
  addTemplatesAfterSaveReducer,
} from './setAddTemplatesAfterSaveReducer'

const formReducers = createReducer(preloadedState.form, builder => {
  builder.addCase(setFormTemplates.type, setFormTemplatesReducer)
  builder.addCase(addTemplatesAfterSave.type, addTemplatesAfterSaveReducer)
})

export default formReducers
