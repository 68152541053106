import isBefore from 'date-fns/isBefore'
import parseISO from 'date-fns/parseISO'
import { IBadge, IStatusDot } from '@ads/front-ds'
import {
  CreationType,
  GenericCampaignPaymentStatus,
  GenericCampaignType,
  ProductType,
  ResponseCampaignForList,
  ResponseGenericCampaign,
} from '@ads/front-core/api'
import {
  Campaign,
  CampaignTypeItem,
  GenericCampaignTypeItem,
  MACampaignTypeItem,
  AllProductTypes,
  AllCampaignTypeItem,
} from '@ads/front-core/interfaces'
import { lng } from '@ads/front-core/locales'

export const campaignTypeLabels: CampaignTypeItem[] = [
  {
    id: 'RETARGETING',
    type: 'ROAS',
    label: lng('campaign:wpBoosterROAS'),
    filters: { type: 'RETARGETING' },
  },
  {
    id: 'PROSPECTING',
    type: 'CPC',
    label: lng('campaign:dynamicDisplay'),
    filters: { type: 'PROSPECTING' },
  },
  {
    id: 'DISPLAY_CPC_BANNER_HTML',
    type: 'CPC',
    label: lng('campaign:display'),
    filters: {
      type: 'DISPLAY_CPC',
      allowedCreationTypes: ['BANNER', 'HTML', 'CODE'],
    },
  },
  {
    id: 'DISPLAY_CPM',
    type: 'CPM',
    label: lng('campaign:display'),
    filters: {
      type: 'DISPLAY_CPM',
      allowedCreationTypes: ['BANNER', 'HTML', 'CODE'],
    },
  },
  {
    id: 'DISPLAY_CPSC',
    type: 'CPSC',
    label: lng('campaign:display'),
    filters: {
      type: 'DISPLAY_CPSC',
      allowedCreationTypes: ['BANNER', 'HTML', 'CODE'],
    },
  },
  {
    id: 'DISPLAY_VCPM',
    type: 'vCPM',
    label: lng('campaign:display'),
    filters: {
      type: 'DISPLAY_VCPM',
      allowedCreationTypes: ['BANNER', 'HTML', 'CODE'],
    },
  },
  {
    id: 'DISPLAY_CPC_NATIVE',
    type: 'CPC',
    label: lng('campaign:nativeAdvertising'),
    filters: { type: 'DISPLAY_CPC', allowedCreationTypes: ['NATIVE'] },
  },
  {
    id: 'PUBLISHERS_DISPLAY_CPC',
    type: 'CPC',
    label: lng('campaign:displayCPCPublishers'),
    filters: {
      type: 'PUBLISHERS_DISPLAY_CPC',
      allowedCreationTypes: ['BANNER', 'HTML', 'CODE'],
    },
  },
  {
    id: 'MAILING_PROSPECTING',
    type: 'CPC',
    label: lng('campaign:mailingProspecting'),
    filters: {
      type: 'MAILING_PROSPECTING',
    },
  },
  {
    id: 'MAILING_RETARGETING',
    type: 'CPC',
    label: lng('campaign:mailingRetargeting.mailingRetargeting'),
    filters: { type: 'MAILING_RETARGETING' },
  },
  {
    id: 'MAILING_STATIC',
    type: 'CPC',
    label: lng('campaign:mailingStatic.mailingShortName'),
    filters: {
      type: 'MAILING_STATIC',
    },
  },
  {
    id: 'MAILING_STATIC_CPM',
    type: 'CPM',
    label: lng('campaign:mailingStaticCPM.mailingShortName'),
    filters: {
      type: 'MAILING_STATIC_CPM',
    },
  },
  {
    id: 'PUBLISHERS_DISPLAY_VCPM',
    type: 'vCPM',
    label: lng('campaign:displayVCPMPublishers'),
    filters: {
      type: 'PUBLISHERS_DISPLAY_VCPM',
      allowedCreationTypes: ['BANNER', 'HTML', 'CODE'],
    },
  },
  {
    id: 'PUBLISHERS_FLAT_FEE',
    type: 'CPC',
    label: lng('campaign:flatFeePublishers'),
    filters: {
      type: 'PUBLISHERS_FLAT_FEE',
      allowedCreationTypes: ['BANNER', 'HTML', 'CODE'],
    },
  },
]

export const maCampaignTypeLabels: MACampaignTypeItem[] = [
  {
    id: 'PUSH',
    type: null,
    label: lng('campaign:push.title'),
    filters: {
      type: 'PUSH',
    },
  },
  {
    id: 'NEWSLETTER',
    type: null,
    label: lng('newsletters:title'),
    filters: {
      type: 'NEWSLETTER',
    },
  },
]

export const genericCampaignTypeLabels: GenericCampaignTypeItem[] = [
  {
    id: 'VIDEO',
    type: undefined,
    label: 'Video',
    filters: {
      type: 'VIDEO',
    },
  },
  {
    id: 'BAR',
    type: undefined,
    label: lng('campaign:marketplace.barAd'),
    filters: {
      type: 'BAR',
    },
  },
  {
    id: 'BAR_LP_WP',
    type: undefined,
    label: lng('campaign:marketplace.barLPWPAd'),
    filters: {
      type: 'BAR_LP_WP',
    },
  },
  {
    id: 'STICKY',
    type: undefined,
    label: lng('campaign:marketplace.stickyMobileAd'),
    filters: {
      type: 'STICKY',
    },
  },
  {
    id: 'CONTENT_BOX',
    type: undefined,
    label: lng('campaign:marketplace.contentBoxAd'),
    filters: {
      type: 'CONTENT_BOX',
    },
  },
  {
    id: 'BUSINESS_SERVICES',
    type: undefined,
    label: lng('campaign:marketplace.businessAd'),
    filters: {
      type: 'BUSINESS_SERVICES',
    },
  },
  {
    id: 'SPORTS_SERVICES',
    type: undefined,
    label: lng('campaign:marketplace.sportsAd'),
    filters: {
      type: 'SPORTS_SERVICES',
    },
  },
  {
    id: 'WEB_PORTALS',
    type: undefined,
    label: lng('campaign:marketplace.webPortalsAd'),
    filters: {
      type: 'WEB_PORTALS',
    },
  },
  {
    id: 'FLAT_FEE',
    type: undefined,
    label: lng('flatfee:title'),
    filters: {
      type: 'FLAT_FEE',
    },
  },
  {
    id: 'FLOATING_HALFPAGE',
    type: undefined,
    label: lng('campaign:marketplace.floatingHalfPage'),
    filters: {
      type: 'FLOATING_HALFPAGE',
    },
  },
  {
    id: 'NATIVE',
    type: undefined,
    label: lng('campaign:marketplace.native'),
    filters: {
      type: 'NATIVE',
    },
  },
  {
    id: 'MIX',
    type: undefined,
    label: lng('campaign:marketplace.mix.title'),
    filters: {
      type: 'MIX',
    },
  },
  {
    id: 'ARTICLE_NATIVE',
    type: undefined,
    label: lng('campaign:marketplace.articles.titleNative'),
    filters: {
      type: 'ARTICLE_NATIVE',
    },
  },
]

export const allNonMACampaignTypeLabels = [
  ...campaignTypeLabels,
  ...genericCampaignTypeLabels,
]

export const allCampaignTypeLabels = [
  ...campaignTypeLabels,
  ...genericCampaignTypeLabels,
  ...maCampaignTypeLabels,
]

export const isGenericCampaign = (productType: AllProductTypes): boolean =>
  genericCampaignTypeLabels.some(g => g.filters.type === productType)

export const getCampaignTypeLabelById = (
  id: string,
): AllCampaignTypeItem | undefined =>
  allCampaignTypeLabels.find(c => c.id === id)

export const getCampaignTypeLabel = (filters: {
  type?: AllProductTypes
  allowedCreationTypes?: Array<CreationType>
}): AllCampaignTypeItem | undefined => {
  const filteredLabels = allCampaignTypeLabels.filter(
    c =>
      !Object.keys(filters)
        .map(filterKey => {
          if (Array.isArray(filters[filterKey])) {
            return !(filters[filterKey] || []).some(
              f => !(c.filters[filterKey] || []).includes(f),
            )
          }
          return c.filters[filterKey] === filters[filterKey]
        })
        .some(c => !c),
  )
  return (filteredLabels || [])[0]
}

export const getCampaignTypeLabelType = (
  type: AllProductTypes,
): AllCampaignTypeItem =>
  allCampaignTypeLabels.find(campaign => campaign.filters.type === type)

export const campaignStatusLabels: Record<
  Campaign['status'] | ResponseGenericCampaign['status'],
  { label: string }
> = {
  INCOMPLETE: {
    label: lng('campaign:incomplete'),
  },
  WAIT_FOR_CONFIRMATION: {
    label: lng('campaign:waitForConfirmation'),
  },
  STARTED: {
    label: lng('campaign:started'),
  },
  PAUSED: {
    label: lng('campaign:paused'),
  },
  ENDED: {
    label: lng('campaign:ended'),
  },
  IN_VERIFICATION: {
    label: lng('campaign:inVerification'),
  },
  REJECTED: {
    label: lng('common:rejected'),
  },
  WAIT_FOR_START_DATE: {
    label: lng('campaign:waitForStartDate'),
  },
}

export const getCampaignStatusLabel = (
  campaignStatus:
    | ResponseCampaignForList['status']
    | ResponseGenericCampaign['status'],
  userPaused?: boolean,
): { label: string } | undefined =>
  campaignStatus === 'PAUSED' && userPaused
    ? { label: lng('campaign:pausedByUser') }
    : campaignStatusLabels[campaignStatus]

export const getCampaignStatus = (
  campaign?: ResponseCampaignForList | Campaign | ResponseGenericCampaign,
): {
  label: string
  badgeVariant: IBadge['variant']
  badgeAppearance: IBadge['appearance']
  statusDotVariant: IStatusDot['variant']
} => {
  if (!campaign) {
    return {
      statusDotVariant: 'grey',
      badgeVariant: 'default',
      badgeAppearance: 'subtle',
      label: lng('campaign:draft'),
    }
  }

  try {
    if (
      campaign?.type === 'RETARGETING' &&
      ['STARTED', 'PAUSED'].includes(campaign?.status) &&
      isBefore(parseISO(campaign?.dateEnd), new Date())
    ) {
      campaign.status = 'PAUSED'
    }
  } catch (e) {
    console.error(e)
  }

  switch (campaign?.status) {
    default:
      return {
        statusDotVariant: 'green',
        badgeVariant: 'default',
        badgeAppearance: 'subtle',
        label: lng('common:unknown'),
      }
    case 'INCOMPLETE':
      return {
        statusDotVariant: 'red',
        badgeVariant: 'alert',
        badgeAppearance: 'solid',
        label: getCampaignStatusLabel(campaign?.status)?.label,
      }
    case 'PAUSED':
      return {
        statusDotVariant: 'orange',
        badgeVariant: 'warning',
        badgeAppearance: 'solid',
        label: getCampaignStatusLabel(
          campaign?.status,
          (campaign as Campaign)?.userPaused,
        )?.label,
      }
    case 'WAIT_FOR_CONFIRMATION':
      return {
        statusDotVariant: 'grey',
        badgeVariant: 'neutral',
        badgeAppearance: 'solid',
        label: getCampaignStatusLabel(campaign?.status)?.label,
      }
    case 'STARTED':
      return {
        statusDotVariant: 'green',
        badgeVariant: 'success',
        badgeAppearance: 'subtle',
        label: getCampaignStatusLabel(campaign?.status)?.label,
      }
    case 'ENDED':
      return {
        statusDotVariant: 'blue',
        badgeVariant: 'neutral',
        badgeAppearance: 'subtle',
        label: getCampaignStatusLabel(campaign?.status)?.label,
      }
    case 'IN_VERIFICATION':
      return {
        statusDotVariant: 'grey',
        badgeVariant: 'neutral',
        badgeAppearance: 'subtle',
        label: getCampaignStatusLabel(campaign?.status)?.label,
      }
    case 'REJECTED':
      return {
        statusDotVariant: 'red',
        badgeVariant: 'alert',
        badgeAppearance: 'subtle',
        label: getCampaignStatusLabel(campaign?.status)?.label,
      }
    case 'WAIT_FOR_START_DATE':
      return {
        statusDotVariant: 'green',
        badgeVariant: 'success',
        badgeAppearance: 'subtle',
        label: getCampaignStatusLabel(campaign?.status)?.label,
      }
  }
}

export const getGenericCampaignPaymentStatus = (
  paymentStatus: GenericCampaignPaymentStatus,
): {
  statusDotVariant: IStatusDot['variant']
  label: string
} => {
  switch (paymentStatus) {
    case 'PAID':
      return {
        statusDotVariant: 'green',
        label: lng('campaign:paid'),
      }
    case 'UNPAID':
      return {
        statusDotVariant: 'red',
        label: lng('campaign:unpaid'),
      }
    case 'PENDING':
      return {
        statusDotVariant: 'yellow',
        label: lng('campaign:pending'),
      }
  }
}

export const isPublisherCampaign = (productType: ProductType) =>
  [
    'PUBLISHERS_DISPLAY_VCPM',
    'PUBLISHERS_DISPLAY_CPC',
    'PUBLISHERS_FLAT_FEE',
  ].includes(productType)

export const getProductTypesByMetricTypes = (
  selectedCampaignTypeIds: string[],
): ProductType[] | GenericCampaignType[] => {
  const productTypes: ProductType[] = allCampaignTypeLabels.reduce(
    (acc, campaignTypeLabel) => {
      if (selectedCampaignTypeIds.includes(campaignTypeLabel.id)) {
        if (campaignTypeLabel.id === 'DISPLAY_CPC_NATIVE') {
          return [...acc, 'DISPLAY_CPC_NATIVE']
        } else if (campaignTypeLabel.filters.type) {
          return [...acc, campaignTypeLabel.filters.type]
        }
      }
      return acc
    },
    [],
  )
  return productTypes
}
