/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Error } from '../models/Error'
import type { NewsletterDefaultSettings } from '../models/NewsletterDefaultSettings'
import type { RequestCreateNewsletter } from '../models/RequestCreateNewsletter'
import type { RequestSendTestNewsletter } from '../models/RequestSendTestNewsletter'
import type { RequestUpdateNewsletter } from '../models/RequestUpdateNewsletter'
import type { ResponseEntityCreated } from '../models/ResponseEntityCreated'
import type { ResponseNewsletter } from '../models/ResponseNewsletter'
import type { ResponseNewsletterList } from '../models/ResponseNewsletterList'
import type { ResponseSendingAccount } from '../models/ResponseSendingAccount'
import type { SendingAccount } from '../models/SendingAccount'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class NewsletterService {
  /**
   * Tworzy nowy newsletter
   * @returns Error unexpected error
   * @returns ResponseEntityCreated newsletter utworzony
   * @throws ApiError
   */
  public static createNewsletter({
    requestBody,
  }: {
    /**
     * Dane newslettera
     */
    requestBody: RequestCreateNewsletter
  }): CancelablePromise<Error | ResponseEntityCreated> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/newsletter',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * pobiera newsletter
   * @returns ResponseNewsletter newsletter
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static getNewsletter({
    newsletterId,
  }: {
    newsletterId: number
  }): CancelablePromise<ResponseNewsletter | Error> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/newsletter/{newsletterId}',
      path: {
        newsletterId: newsletterId,
      },
    })
  }

  /**
   * Aktualizuje newsletter
   * @returns Error unexpected error
   * @returns any dane zapisane
   * @throws ApiError
   */
  public static updateNewsletter({
    newsletterId,
    requestBody,
  }: {
    newsletterId: number
    /**
     * Dane newslettera
     */
    requestBody: RequestUpdateNewsletter
  }): CancelablePromise<Error | any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/newsletter/{newsletterId}',
      path: {
        newsletterId: newsletterId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Usuwa newsletter
   * @returns Error unexpected error
   * @returns any newsletter usunięty
   * @throws ApiError
   */
  public static deleteNewsletter({
    newsletterId,
  }: {
    newsletterId: number
  }): CancelablePromise<Error | any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/newsletter/{newsletterId}',
      path: {
        newsletterId: newsletterId,
      },
    })
  }

  /**
   * Wysyła testowy newsletter
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static sendTestNewsletter({
    newsletterId,
    requestBody,
  }: {
    newsletterId: number
    /**
     * Dane newslettera
     */
    requestBody: RequestSendTestNewsletter
  }): CancelablePromise<Error> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/newsletter/{newsletterId}/send-test',
      path: {
        newsletterId: newsletterId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Zmienia status uruchomienia newslettera na uruchomiony
   * @returns Error unexpected error
   * @returns any newsletter uruchomiony
   * @throws ApiError
   */
  public static startNewsletter({
    newsletterId,
  }: {
    newsletterId: number
  }): CancelablePromise<Error | any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/newsletter/{newsletterId}/start',
      path: {
        newsletterId: newsletterId,
      },
    })
  }

  /**
   * Zmienia status uruchomienia newslettera na nieuruchomiony
   * @returns Error unexpected error
   * @returns any newsletter zatrzymany
   * @throws ApiError
   */
  public static stopNewsletter({
    newsletterId,
  }: {
    newsletterId: number
  }): CancelablePromise<Error | any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/newsletter/{newsletterId}/stop',
      path: {
        newsletterId: newsletterId,
      },
    })
  }

  /**
   * pobiera listę newsletter
   * @returns ResponseNewsletterList lista newsletter
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static getNewsletterList({
    shopId,
    page,
    limit,
    sortDir,
    newsletterStatus,
    startDateMin,
    startDateMax,
    searchString,
  }: {
    shopId: number
    /**
     * numer strony do pobrania
     */
    page: number
    /**
     * liczba elementów do pobrania
     */
    limit: number
    /**
     * kierunek sortowania
     */
    sortDir?: 'asc' | 'desc'
    /**
     * filtr - status newslettera
     */
    newsletterStatus?:
      | 'DRAFT'
      | 'NOT_STARTED'
      | 'WAITING_FOR_ACCEPTANCE'
      | 'ACCEPTED'
      | 'REJECTED'
      | 'SENT'
    /**
     * filtr - minimalny zakres daty startu
     */
    startDateMin?: string
    /**
     * filtr - maksymalny zakres daty startu
     */
    startDateMax?: string
    /**
     * filtr - wyszukiwany tekst
     */
    searchString?: string
  }): CancelablePromise<ResponseNewsletterList | Error> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/shop/{shopId}/newsletter',
      path: {
        shopId: shopId,
      },
      query: {
        page: page,
        limit: limit,
        sortDir: sortDir,
        newsletterStatus: newsletterStatus,
        startDateMin: startDateMin,
        startDateMax: startDateMax,
        searchString: searchString,
      },
    })
  }

  /**
   * pobiera domyśle ustawienia newsletterów dla sklepu
   * @returns NewsletterDefaultSettings newsletterDefaultSettings
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static getNewsletterDefaultSettings({
    shopId,
  }: {
    shopId: number
  }): CancelablePromise<NewsletterDefaultSettings | Error> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/newsletter/default-settings/{shopId}',
      path: {
        shopId: shopId,
      },
    })
  }

  /**
   * Aktualizuje domyśle ustawienia newsletterów dla sklepu
   * @returns Error unexpected error
   * @returns any dane zapisane
   * @throws ApiError
   */
  public static updateNewsletterDefaultSettings({
    shopId,
    requestBody,
  }: {
    shopId: number
    /**
     * Dane domyślnych ustawień newslettera
     */
    requestBody: NewsletterDefaultSettings
  }): CancelablePromise<Error | any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/newsletter/default-settings/{shopId}',
      path: {
        shopId: shopId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Tworzy nowe domyśle ustawienia newsletterów dla sklepu
   * @returns Error unexpected error
   * @returns any domyślne ustawienia newslettera utworzone
   * @throws ApiError
   */
  public static createNewsletterDefaultSettings({
    shopId,
    requestBody,
  }: {
    shopId: number
    /**
     * Dane domyślnych ustawień newslettera
     */
    requestBody: NewsletterDefaultSettings
  }): CancelablePromise<Error | any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/newsletter/default-settings/{shopId}',
      path: {
        shopId: shopId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * zwraca listę kont wysyłkowych
   * @returns ResponseSendingAccount newsletterDefaultSettings
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static getSendingAccounts({
    shopId,
  }: {
    shopId: number
  }): CancelablePromise<Array<ResponseSendingAccount> | Error> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/newsletter/sending-account/{shopId}',
      path: {
        shopId: shopId,
      },
    })
  }

  /**
   * Tworzy nowe konto wysyłkowe
   * @returns Error unexpected error
   * @returns any konto wysyłkowe utworzone
   * @throws ApiError
   */
  public static createSendingAccount({
    shopId,
    requestBody,
  }: {
    shopId: number
    /**
     * Tworzy nowe konto wysyłkowe
     */
    requestBody: SendingAccount
  }): CancelablePromise<Error | any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/newsletter/sending-account/{shopId}',
      path: {
        shopId: shopId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Aktualizuje konto wysyłkowe
   * @returns Error unexpected error
   * @returns any dane zapisane
   * @throws ApiError
   */
  public static updateSendingAccount({
    shopId,
    accountId,
    requestBody,
  }: {
    shopId: number
    accountId: number
    /**
     * Dane konta wysyłkowego
     */
    requestBody: SendingAccount
  }): CancelablePromise<Error | any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/newsletter/sending-account/{shopId}/{accountId}',
      path: {
        shopId: shopId,
        accountId: accountId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Wypisanie użytkownika z newslettera
   * @returns Error unexpected error
   * @returns any pomyślnie wypisano użytkownika
   * @throws ApiError
   */
  public static newsletterOptOut({
    codedData,
    reason,
  }: {
    codedData: string
    reason?: string
  }): CancelablePromise<Error | any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/newsletter/optout',
      query: {
        codedData: codedData,
        reason: reason,
      },
    })
  }

  /**
   * Zapisanie się użytkownika na newsletter
   * @returns Error unexpected error
   * @returns any pomyślnie zapisano użytkownika
   * @throws ApiError
   */
  public static newsletterOptIn({
    codedData,
  }: {
    codedData: string
  }): CancelablePromise<Error | any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/newsletter/optin',
      query: {
        codedData: codedData,
      },
    })
  }
}
